const initCookies = () => {
  try {
    const expire_date = new Date();
    expire_date.setTime(expire_date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
    const expires = expire_date.toGMTString();
    const lp = window.location.toString();
    const refer = document.referrer;
    const cookie_domain = window.location.hostname
      .split(".")
      .slice(-2)
      .join(".");
    const qparams = window.location.search.substr(1).split("&");
    let do_track = false;
    for (var i = 0; i < qparams.length; i++) {
      if (qparams[i].indexOf("bid=") === 0) {
        do_track = true;
      }
      if (qparams[i].indexOf("mkwid=") === 0) {
        do_track = true;
      }
      if (qparams[i].indexOf("utm_source=") === 0) {
        do_track = true;
      }
      if (document.cookie.indexOf("beaker_last") === -1) {
        do_track = true;
      }
    }
    if (do_track) {
      document.cookie =
        "beaker_last=" +
        lp +
        "; expires=" +
        expires +
        "; path=/; domain=." +
        cookie_domain;
    }
    if (document.cookie.indexOf("beaker_lp") === -1) {
      document.cookie =
        "beaker_lp=" +
        lp +
        "; expires=" +
        expires +
        "; path=/; domain=." +
        cookie_domain;
    }
    if (document.cookie.indexOf("beaker_refer") === -1) {
      document.cookie =
        "beaker_refer=" +
        refer +
        "; expires=" +
        expires +
        "; path=/; domain=." +
        cookie_domain;
    }
  } catch (e) {}
};

export default initCookies;

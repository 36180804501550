import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import theme from "../ui/theme";
import Img from "gatsby-image";

const LeaderboardWrapper = styled.section`
  background: #081a31;
  border-bottom: solid 1px #fff;
  padding: 0 30px;
  .leaderboard-inner {
    align-items: center;
    background: #081a31;
    display: flex;
    justify-content: center;
    gap: 20px;
    max-width: 960px;
    margin: 0 auto;
    padding: 100px 0px;
    h2 {
      color: #fff;
      font-size: 36px;
      line-height: 49px;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
    h3 {
      color: #fff;
      font-size: 20px;
      line-height: 27px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      span {
        color: #ffc71b;
      }
    }
    img {
      max-width: 260px;
      width: 100%;
    }
    .leaderboard__text {
      text-align: center;
    }
    .btn {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      padding: 10px 30px;
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      padding: 50px 0;
      flex-wrap: wrap;
      h2 {
        padding: 12px 0 0;
        font-size: 24px;
        line-height: 34px;
      }
      h3 {
        margin-top: 10px;
      }
      img {
        max-width: 180px;
      }
      .btn {
        padding: 10 24px;
      }
    }
  }
  .leaderboard-image {
    margin-bottom: -30px;
    text-align: center;
    margin-bottom: 10px;
    &.leaderboard-image--default {
      overflow-y: hidden;
    }
    @media screen and (max-width: ${theme.breakpoints.md}) {
      flex-basis: 100%;
      max-height: 135px;
      margin-bottom: 0;
      overflow: hidden;
    }
  }
  &.banner {
    background: none;
    border-bottom: 0;
    margin-bottom: 50px;
    .leaderboard-inner {
      overflow: hidden;
      padding: 20px;
      @media screen and (max-width: ${theme.breakpoints.md}) {
        max-width: 840px;
      }
    }
    h2 {
      color: #3ac0a4;
    }
    .leaderboard__image {
      max-height: 200px;
      margin-bottom: -30px;
      @media screen and (max-width: ${theme.breakpoints.md}) {
        max-height: 140px;
        margin-bottom: 0;
        overflow: hidden;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 0 20px;
  }
`;

const Leaderboard = (props) => {
  const { layout, btnLink } = props;
  const heading = getContent(props, "heading");
  const subheading = getContent(props, "subheading");
  const btnText = getContent(props, "btnText");
  const btnColor = getContent(props, "btnColor");
  const imageAlt = getContent(props, "imageAlt");
  const mainClassName = "leaderboard-inner";
  const imgClassName = `leaderboard-image leaderboard-inner--default`;

  const { leaderBoardImage } = useStaticQuery(graphql`
    query LeaderBoardQueryNurses {
      leaderBoardImage: file(relativePath: { eq: "nurses.png" }) {
        childImageSharp {
          fixed(width: 165) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const LeaderboardButton = () => {
    if (btnLink && btnLink.indexOf("http") !== -1) {
      return (
        <a
          className={btnColor ? `btn btn--${btnColor}` : btnColor}
          href={btnLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {btnText}
        </a>
      );
    } else {
      return (
        <Link
          className={btnColor ? `btn btn--${btnColor}` : btnColor}
          to={btnLink}
        >
          {btnText}
        </Link>
      );
    }
  };

  return (
    <LeaderboardWrapper className={layout}>
      <div className={mainClassName}>
        <div className={imgClassName}>
          <Img
            alt={imageAlt}
            fixed={
              leaderBoardImage &&
              leaderBoardImage.childImageSharp &&
              leaderBoardImage.childImageSharp.fixed
            }
          />
        </div>
        <div className="leaderboard__text">
          <h2>{heading}</h2>
          {subheading && <h3>{subheading}</h3>}
          <LeaderboardButton />
        </div>
      </div>
    </LeaderboardWrapper>
  );
};

const defaultContent = {
  btnText: {
    default: `Become a Travel Nurse!`,
  },
  btnColor: {
    default: `btn btn--primary text-black`,
  },
  heading: {
    default: `Join the many nurses already traveling.`,
  },
  subheading: {
    default: `Don't miss out on your adventure.`,
  },
  image: {
    default: "nursesStanding",
  },
  imageAlt: {
    default: "Three nurses standing",
  },
};

const getContent = (props, propName) => {
  const { type } = props;
  if (props[propName] === false) return;
  return (
    props[propName] ||
    defaultContent[propName][type] ||
    defaultContent[propName]["default"]
  );
};

Leaderboard.defaultProps = {
  btnText: "",
  btnLink: `/sign-up-full/`,
  btnColor: "",
  heading: "",
  subheading: "",
};

export default Leaderboard;

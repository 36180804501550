const theme = {
  breakpoints: {
    xs: "320px",
    sm: "580px",
    md: "640px",
    lg: "768px",
    xl: "1100px",
    xxl: "1400px",
  },
};

export default theme;

import styled from "styled-components";
import theme from "../ui/theme";

const PostGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1440px;

  @media all and (max-width: 1024px) {
    max-width: 625px;
  }

  @media all and (max-width: ${theme.breakpoints.md}) {
    max-width: 400px;
  }

  .load-more {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const Item = styled.article`
  background: #fff;
  flex-basis: ${(props) => (props.width ? props.width : "31.5%")};
  margin-bottom: 1.8rem;
  transition: box-shadow 0.2s ease-out;

  @media all and (max-width: 1024px) {
    flex-basis: 48.65%;
    margin-bottom: 1.45rem;
  }

  @media all and (max-width: ${theme.breakpoints.md}) {
    flex-basis: 100%;
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 5px 15px #e6e6e6;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  .gatsby-image-wrapper {
    height: 170px;
  }

  h3 {
    font-size: 16px;
    margin: 0;
    padding: 20px 13px;
  }

  span {
    color: #555;
    display: block;
    font-size: 13px;
    margin-top: -10px;
    padding: 0 13px 20px;
  }

  .search-results &,
  .blog &,
  .author & {
    margin-right: 2.7%;

    @media all and (min-width: 1025px) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media all and (max-width: 1024px) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media all and (max-width: ${theme.breakpoints.md}) {
      margin-right: 0;
    }
  }

  .blog.page-1 & {
    @media all and (min-width: 1025px) {
      &:nth-child(3n) {
        margin-right: inherit;
      }

      &:nth-child(3n + 1) {
        margin-right: 0;
      }
    }

    @media all and (max-width: 1024px) {
      &:nth-child(2n) {
        margin-right: inherit;
      }

      &:nth-child(2n + 1) {
        margin-right: 0;
      }
    }

    &:first-of-type {
      flex-basis: 100%;
      margin-right: 0;
      position: relative;
      .gatsby-image-wrapper {
        height: 480px;
        @media all and (max-width: ${theme.breakpoints.md}) {
          height: 260px;
        }
      }

      h3 {
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        font-size: 30px;
        left: 0;
        margin: 0 20px 20px;
        padding: 20px;
        position: absolute;
        right: 0px;
        text-align: center;

        @media all and (max-width: ${theme.breakpoints.md}) {
          font-size: 20px;
          margin: 0;
        }
      }
    }
  }
`;

PostGrid.Item = Item;

export default PostGrid;

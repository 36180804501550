/* eslint jsx-a11y/click-events-have-key-events: 0 */
import { Link } from "gatsby";
import throttle from "lodash/throttle";
import React, { Component } from "react";
import { FaBars, FaSearch, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import logo from "../images/logo.svg";
import "../styles/buttons.scss";
import Navigation from "./Navigation";
import MobileSidebar from "./MobileSidebar";
import SearchBar from "./SearchBar";
import cx from "classnames";

const HeaderWrapper = styled.header`
  background-color: #081a31;
  position: relative;
  width: 100%;
  z-index: 2;
  transition: background-color 0.2s linear;

  .header__logo {
    a {
      display: block;
      line-height: 0.8;
    }

    img {
      height: 33px;
      margin: 0;
      width: 260px;
    }
  }

  .header__toggle-search {
    flex-basis: 6%;
    text-align: right;
    svg {
      cursor: pointer;
      fill: #fff;
      height: 22px;
      width: 22px;
    }
  }

  .header__cta a {
    font-size: 15px;
  }

  .header__hamburger {
    padding: 5px;

    svg {
      cursor: pointer;
      fill: #fff;
      height: 22px;
      width: 20px;
    }

    @media all and (min-width: 1080px) {
      display: none;
    }
  }

  .header__instagram {
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-align: right;
    padding: 6.5px 0 6.5px 74px;

    svg {
      cursor: pointer;
      fill: #fff;
      height: 24px;
      margin-right: 5px;
      width: 24px;
    }

    a {
      align-items: center;
      color: #fff;
      display: flex;
      justify-content: flex-end;
      text-decoration: none;
    }
  }

  @media all and (max-width: 1079px) {
    &:not(.minimal) {
      position: sticky;
      top: 0;
    }

    .header__logo {
      img {
        height: 24px;
        width: 189px;
      }
    }

    .nav__dropdown,
    .header__toggle-search,
    .header__cta,
    .header__instagram {
      display: none;
    }
  }

  &.transparent:not(.dropdown-open) {
    background-color: transparent;
  }
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: 17px 20px;
  transition: background-color 0.15s linear;

  @media all and (max-width: 1079px) {
    border-bottom: solid 1px #fff;
    padding: 12px 15px 12px 20px;

    .transparent & {
      border-bottom: 0;
    }

    .minimal & {
      padding: 17px 20px;
    }

    &.scrolled-down:not(.minimal),
    &.sidebar-open:not(.minimal) {
      background: #081a31;
      border-bottom: solid 1px #fff;
    }
  }

  @media all and (max-width: 700px) {
    .minimal & {
      justify-content: center;
    }
  }
`;

const Hoverlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.2s ease-out;

  &.active {
    width: 100vw;
    height: 100vh;
    margin-top: 1px;
    opacity: 1;
    position: fixed;
    top: 0;
    z-index: -2;
  }
`;

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDesktopSearch: false,
      showMobileSidebar: false,
      hasScrolledDown: false,
      navDropdownIsOpen: false,
    };

    this.headerContainerRef = React.createRef();
  }

  // Toggle the search bar, add listeners to close it
  toggleSearch = () => {
    if (!this.state.showDesktopSearch) {
      this.setState({ showDesktopSearch: true });

      setTimeout(() => {
        document.addEventListener("click", this.handleCloseSearch);
      }, 50);

      window.addEventListener("resize", this.handleCloseSearch);
    } else {
      this.hideSearch();
    }
  };

  hideSearch = () => {
    this.setState({
      showDesktopSearch: false,
    });
    document.removeEventListener("click", this.handleCloseSearch);
    window.removeEventListener("resize", this.handleCloseSearch);
  };

  // Close the search bar and remove listeners
  handleCloseSearch = (evt) => {
    if (evt.type === "click" && this.headerContainerRef.current != null) {
      if (!this.headerContainerRef.current.contains(evt.target)) {
        this.hideSearch();
      }
    } else if (evt.type === "resize" && evt.target.innerWidth < 1080) {
      this.hideSearch();
    }
  };

  // Toggle the mobile sidebar, add no-scroll to body, add event listener to close it
  toggleSidebar = () => {
    this.setState(
      {
        showMobileSidebar: !this.state.showMobileSidebar,
      },
      () => {
        document.body.classList.toggle("no-scroll");
        if (this.state.showMobileSidebar) {
          window.addEventListener("resize", this.closeMobileSidebarOnResize);
        }
      },
    );
  };

  closeMobileSidebarOnResize = (event) => {
    if (event.target.innerWidth >= 1080) {
      this.closeMobileSidebar();
    }
  };

  // Close mobile sidebar, remove no-scroll and listeners
  closeMobileSidebar = (evt) => {
    this.setState({
      showMobileSidebar: false,
    });
    document.body.classList.remove("no-scroll");
    window.removeEventListener("resize", this.closeMobileSidebarOnResize);
  };

  // When scrolling add the dark background to the header
  handleScroll = () => {
    const { hasScrolledDown } = this.state;

    let scrollTop = 0;
    try {
      // Yay IE support
      scrollTop = window.pageYOffset || document.documentElement.scrollTop || 0;
    } catch (e) {}

    if (!hasScrolledDown && scrollTop > 0) {
      this.setState({ hasScrolledDown: true });
    } else if (hasScrolledDown && scrollTop === 0) {
      this.setState({ hasScrolledDown: false });
    }
  };

  // Keep track of the dropdown menu being open/closed
  onDropdownToggle = (isVisible) => {
    this.setState({ navDropdownIsOpen: isVisible });
  };

  componentDidMount() {
    // Remove the no-scroll class when switching between pages
    document.body.classList.remove("no-scroll");

    // Check if header is transparent, then add listener for scrolling
    if (
      this.props.headerStyle &&
      this.props.headerStyle.indexOf("transparent") !== -1 &&
      this.props.headerStyle.indexOf("no-scroll") === -1
    ) {
      // Throttle scroll event so it's called at most 4 times per second
      this.throttledScroll = throttle(this.handleScroll, 250);
      window.addEventListener("scroll", this.throttledScroll);
    }
  }

  render() {
    const { headerStyle = "", template } = this.props;
    const {
      showMobileSidebar,
      hasScrolledDown,
      navDropdownIsOpen,
      showDesktopSearch,
    } = this.state;

    return (
      <HeaderWrapper
        id="header"
        className={cx(headerStyle, {
          "sidebar-open": showMobileSidebar,
          "dropdown-open": navDropdownIsOpen,
        })}
      >
        <HeaderContainer
          ref={this.headerContainerRef}
          className={cx({
            "sidebar-open": showMobileSidebar,
            "scrolled-down": hasScrolledDown,
          })}
        >
          {headerStyle.indexOf("minimal") !== -1 ? (
            <div className="header__logo">
              <Link to="/">
                <img src={logo} alt={this.props.siteTitle} />
              </Link>
            </div>
          ) : (
            <>
              <SearchBar searchState={showDesktopSearch ? "active" : ""} />

              <div className="header__logo">
                <Link to="/">
                  <img src={logo} alt={this.props.siteTitle} />
                </Link>
              </div>
              <Navigation
                searchState={showDesktopSearch}
                navigation={this.props.navigation}
                mode="dropdown"
                dropdownToggle={this.onDropdownToggle}
              />
              <div
                role="button"
                tabIndex={0}
                className="header__toggle-search"
                onClick={this.toggleSearch}
              >
                {showDesktopSearch ? <FaTimes /> : <FaSearch />}
              </div>
              <div
                role="button"
                tabIndex={0}
                className="header__hamburger"
                onClick={this.toggleSidebar}
              >
                {showMobileSidebar ? <FaTimes /> : <FaBars />}
              </div>
              {!template === "index" ||
                (!template === "confirmation" && (
                  // TODO: Add this back once the package is updated
                  // https://github.com/oorestisime/gatsby-source-instagram/issues?q=is%3Aissue+is%3Aopen+sort%3Aupdated-desc
                  //
                  // <div className="header__instagram">
                  //   <a
                  //     href="https://www.instagram.com/travel_nursing"
                  //     target="_blank"
                  //     rel="noopener noreferrer"
                  //   >
                  //     <FaInstagram /> <InstagramFollowerCount />
                  //   </a>
                  // </div>
                  <div className="header__cta">
                    <Link to="/sign-up-full/" className="btn btn--secondary">
                      Start Traveling
                    </Link>
                  </div>
                ))}
            </>
          )}
        </HeaderContainer>
        <MobileSidebar
          navigation={this.props.navigation}
          showSidebar={showMobileSidebar}
          currentTemplate={this.props.template}
          onSearch={this.closeMobileSidebar}
        />
        <Hoverlay
          className={showMobileSidebar ? "active" : ""}
          onClick={this.toggleSidebar}
        />
      </HeaderWrapper>
    );
  }
}

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  SearchBarForm,
  RotatingIcon,
  SearchBarButton,
  SearchBarInput,
} from "../ui/Search";
import { navigate } from "gatsby";
import { FaSearch, FaSync } from "react-icons/fa";

const SearchBar = ({
  searchState,
  isSearchResultsPage,
  isSearching = false,
  onSearch,
}) => {
  const inputRef = useRef();
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (searchState === "active") {
      inputRef.current.focus();
    }
  }, [searchState]);

  const handleKeyUp = (event) => {
    // Search when users press Enter
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (onSearch != null) {
      onSearch(query);
    }

    navigate(`/search-results/?search=${encodeURIComponent(query)}`);
  };

  return (
    <SearchBarForm
      className={searchState || ""}
      onSubmit={(e) => e.preventDefault()}
      isSearchResultsPage={isSearchResultsPage}
    >
      <SearchBarInput
        ref={inputRef}
        type="text"
        placeholder="Search topics"
        disabled={isSearching}
        onKeyUp={handleKeyUp}
        onChange={(event) => setQuery(event.currentTarget.value)}
      />
      <SearchBarButton
        type="button"
        disabled={query.length === 0 || isSearching}
        onClick={handleSearch}
      >
        {isSearching ? (
          <RotatingIcon>
            <FaSync />
          </RotatingIcon>
        ) : (
          <FaSearch />
        )}
      </SearchBarButton>
    </SearchBarForm>
  );
};

SearchBar.propTypes = {
  searchState: PropTypes.string,
  isSearchResultsPage: PropTypes.bool,
  isSearching: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default SearchBar;

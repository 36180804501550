import { Link } from "gatsby";
import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { FaCaretDown } from "react-icons/fa";

const NavigationWrapper = styled.nav`
  flex-basis: 50%;
  opacity: 1;
  text-align: center;
  transition: opacity 0.2s ease-out;

  > a {
    border-bottom: solid 2px transparent;
    color: #fff;
    display: inline-block;
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin: 0 15px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s ease-out;
    &:hover,
    &[aria-current],
    &.active {
      border-bottom-color: #3ac0a4;
      color: #3ac0a4;
    }
    svg {
      font-size: 18px;
      vertical-align: text-bottom;
    }
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  &.nav__accordion {
    margin-bottom: 30px;
    text-align: center;
    > a {
      border-bottom: solid 1px #555;
      border-top: solid 1px #555;
      display: block;
      font-size: 12px;
      margin: 0 -30px;
      padding: 20px 30px;
      &:not(:last-of-type) {
        border-bottom-color: #081a31;
      }
      &.active {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
`;

const SubMenu = styled.div`
  font-size: 13px;
  visibility: hidden;

  .nav__dropdown & {
    background: #081a31;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    margin-top: 25px;
    opacity: 0;
    padding: 2.5rem;
    text-align: left;
    transition: all 0.2s linear;
    width: 100%;

    div {
      margin: 0 1rem;
    }

    .heading {
      color: #3ac0a4;
    }

    a {
      color: #fff;
      margin: 0 0 1.45rem;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .nav__accordion & {
    background: #f4f5f7;
    margin: 0 -30px;
    max-height: 0;
    overflow: hidden;
    text-align: center;
    transition: all 0.2s linear;

    div {
      padding: 30px 20px;
    }

    .heading {
      color: #000;
    }

    a {
      color: #081a31;
      line-height: 1.5;
      margin: 0 0 1.25rem;
    }

    &.active {
      max-height: 600px;
      visibility: visible;
    }
  }

  .heading {
    display: block;
    font-weight: bold;
    margin: 0 0 1.45rem;
    text-transform: uppercase;
  }

  a {
    display: block;
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      text-decoration: underline;
      color: #3ac0a4;
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode, // nav__dropdown or nav__accordion
      subMenu: false,
      active: false, // Determine if dropdown is active
    };
  }

  toggleSubMenu = (evt, field) => {
    evt.preventDefault();

    if (this.state.mode === "dropdown") {
      // Set the menuId onto the subMenu state, set to active, update the header dropdownToggle, then add event listeners
      this.setState(
        {
          subMenu: field,
          active: !this.state.active,
        },
        () => {
          if (this.state.active) {
            document.addEventListener("click", this.handleCloseSubMenu);
            window.addEventListener("resize", this.handleCloseSubMenu);
            this.props.dropdownToggle(true);
          } else {
            document.removeEventListener("click", this.handleCloseSubMenu);
            window.removeEventListener("resize", this.handleCloseSubMenu);
            this.props.dropdownToggle(false);
          }
        },
      );
    } else {
      // Accordion, check if current target is already active
      if (field === this.state.subMenu) {
        this.setState({
          active: !this.state.active,
        });
      } else {
        this.setState({
          subMenu: field,
          active: true,
        });
      }
    }
  };

  // Listen for clicks and window resize to determine when to close submenus
  handleCloseSubMenu = (evt) => {
    if (evt.type === "click") {
      // Target the active submenu to determine when it's clicked outside of, update the header dropdownToggle, and remove the listeners
      const subMenu = document.getElementById(this.state.subMenu);
      if (subMenu != null && !subMenu.contains(evt.target)) {
        this.setState(
          {
            subMenu: false,
            active: false,
          },
          () => {
            document.removeEventListener("click", this.handleCloseSubMenu);
            window.removeEventListener("resize", this.handleCloseSubMenu);
            this.props.dropdownToggle(false);
          },
        );
      }
    } else if (evt.type === "resize" && evt.target.innerWidth < 1080) {
      // When the window gets below 1080px close the submenu, update the header dropdownToggle, and remove the listeners
      this.setState(
        {
          subMenu: false,
          active: false,
        },
        () => {
          document.removeEventListener("click", this.handleCloseSubMenu);
          window.removeEventListener("resize", this.handleCloseSubMenu);
          this.props.dropdownToggle(false);
        },
      );
    }
  };

  // Links need parsing since only local urls can use Gatsby Link
  parseLink = (item) => {
    const isWordPressSiteLink =
      item.url.indexOf("travelnursing.org") !== -1 ||
      item.url.indexOf("fullbeaker.dev") !== -1;

    // Use an <a> tag for extenal links
    if (!isWordPressSiteLink && item.url.indexOf("http") !== -1) {
      return (
        <a href={item.url} key={item.object_slug}>
          {item.title}
        </a>
      );
    } else {
      return (
        <Link to={`/${item.object_slug}/`} key={item.object_slug}>
          {item.title}
        </Link>
      );
    }
  };

  render() {
    return (
      // WordPress submenu must have 3 levels to work properly
      // Level 2 becomes submenu headings, level 3 becomes submenu links
      <NavigationWrapper
        className={
          this.props.searchState
            ? `nav__${this.state.mode} hide`
            : `nav__${this.state.mode}`
        }
      >
        {this.props.navigation.map((item, index) => {
          let menuId = this.state.mode + index; // Create a unique id for each dropdown / accordion
          if (item.wordpress_children) {
            return (
              <Fragment key={`dropdown${index}`}>
                <a
                  href={!item.url ? "#" : `/${item.object_slug}`}
                  className={
                    this.state.active && this.state.subMenu === menuId
                      ? "active"
                      : ""
                  }
                  onClick={(evt) => this.toggleSubMenu(evt, menuId)}
                >
                  {item.title} <FaCaretDown />
                </a>
                <SubMenu
                  id={menuId}
                  className={
                    this.state.active && this.state.subMenu === menuId
                      ? "active"
                      : ""
                  }
                >
                  {item.wordpress_children.map((subheader, index) => {
                    if (subheader.wordpress_children) {
                      return (
                        <div key={`subcolumn${index}`}>
                          <span className="heading">{subheader.title}</span>
                          {subheader.wordpress_children.map((subitem) => {
                            return this.parseLink(subitem);
                          })}
                        </div>
                      );
                    }
                    return null;
                  })}
                </SubMenu>
              </Fragment>
            );
          } else {
            return this.parseLink(item);
          }
        })}
      </NavigationWrapper>
    );
  }
}

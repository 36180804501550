import styled, { css, keyframes } from "styled-components";

export const SearchBarInput = styled.input`
  box-shadow: none;
  background-color: transparent;
  border: 0;
  border-bottom: solid 1px #fff;
  border-radius: 0;
  color: #fff;
  font-size: 15px;
  outline: none;
  padding: 5px 52px 5px 18px;
  width: 100%;
`;

export const SearchBarButton = styled.button`
  background: none;
  border: 0;
  bottom: 1px;
  cursor: pointer;
  padding: 5px 20px;
  position: absolute;
  right: 0;

  &[disabled] {
    svg {
      fill: #fafafa;
    }
  }

  svg {
    fill: #fff;
  }
`;

const rotatingAnim = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

export const RotatingIcon = styled.div`
  animation: ${rotatingAnim} 1s linear infinite;
`;

const onPageStyles = css`
  margin: 0 0 40px;
  opacity: 1;
  visibility: visible;

  ${SearchBarInput} {
    font-family: "Montserrat", "Open Sans", arial, sans-serif;
    padding: 10px 15px;
    color: #081a31;
    border-bottom: solid 1px #081a31;
    @media (min-width: 641px) {
      font-size: 30px;
    }
  }

  ${SearchBarButton} {
    font-size: 23px;
    padding: 13px 20px;

    &[disabled] {
      svg {
        fill: #ced0d5;
      }
    }

    svg {
      fill: #081a31;
    }
  }
`;

export const SearchBarForm = styled.form`
  flex-basis: 100%;
  margin: -31px 20% 0 35%;
  opacity: 0;
  position: relative;
  transform: translateY(0);
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-out;
  visibility: hidden;

  &.active {
    opacity: 1;
    transform: translateY(35px);
    visibility: visible;
  }

  /* Styles for when type "onpage" is passed: (e.g: search-results page) */
  ${(props) => props.isSearchResultsPage && onPageStyles}
`;

import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import "../styles/buttons.scss";
import nursesStanding from "../images/nurses-group.png";
import SearchBar from "./SearchBar";
import Navigation from "./Navigation";

const MobileSidebarWrapper = styled.aside`
  background: #081a31;
  height: 100vh;
  margin-top: 1px;
  padding-top: 58px;
  position: fixed;
  right: 0;
  top: 0;
  transition: width 0.3s ease-out;
  max-width: 400px;
  width: ${(props) => (props.showSidebar ? "100vw" : "0")};
  z-index: -1;

  form {
    margin: 0 0 30px;
    opacity: 1;
    visibility: visible;

    input[type="text"] {
      background-color: #fff;
      border-bottom: 0;
      color: inherit;
      padding: 10px 42px 10px 14px;
    }

    button {
      bottom: 0;
      padding: 8px 10px;
    }

    svg,
    button[disabled] svg {
      fill: #ccc;
      font-size: 22px;
    }
  }
`;

const CTAContainer = styled.div`
  text-align: center;

  p {
    color: #fff;
    font-size: 15px;

    strong {
      color: #3ac0a4;
    }
  }

  .btn {
    margin-bottom: 2rem;
  }

  img {
    max-width: 200px;
  }
`;

const MobileSidebarContainer = styled.div`
  background: #081a31;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  padding: 30px;
  width: 350px;

  @media all and (max-width: 350px) {
    width: 100vw;
  }
`;

const MobileSidebar = ({
  onSearch,
  showSidebar,
  navigation,
  currentTemplate,
}) => (
  <MobileSidebarWrapper showSidebar={showSidebar}>
    <MobileSidebarContainer>
      <SearchBar onSearch={onSearch} />
      <Navigation mode="accordion" navigation={navigation} />
      <CTAContainer>
        <p>
          <strong>Earn up to $2,300 a week</strong> at top-tier hospitals across
          the country!
        </p>
        <Link className="btn btn--primary text-black" to="/sign-up-full/">
          Start Traveling!
        </Link>
        <img src={nursesStanding} alt="Three nurses standing" />
      </CTAContainer>
    </MobileSidebarContainer>
  </MobileSidebarWrapper>
);

export default MobileSidebar;

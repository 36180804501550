/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import placeholderImage from "../images/default.jpg";
import favicon from "../images/favicon.png";
import tnlogo from "../images/tn-logo.png";

function Seo({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  canonical,
  schemaType,
  datePublished,
  dateModified,
  author,
  hardcodedCanonicalUrl,
}) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          title
          description
          author
          baseUrl
          twitter
          siteDomain
          wpDomain
        }
      }
    }
  `);

  const schemaData_WebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: canonical,
    headline: site.description,
    inLanguage: "en",
    mainEntityOfPage: site.baseUrl,
    description: description,
    name: title,
    datePublished,
    dateModified,
    image: {
      "@type": "ImageObject",
      url: image,
    },
  };

  const schemaData_Article = {
    "@context": "http://schema.org",
    "@type": "Article",
    author: {
      "@type": "Person",
      name: author && author.name,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author && author.name,
    },
    copyrightYear: `${new Date().getFullYear()}`,
    creator: {
      "@type": "Person",
      name: author && author.name,
    },
    publisher: {
      "@type": "Organization",
      name: author && author.name,
      logo: {
        "@type": "ImageObject",
        url: tnlogo,
      },
    },
    datePublished,
    dateModified,
    description: description,
    headline: title,
    inLanguage: "en",
    url: canonical,
    name: title,
    image: {
      "@type": "ImageObject",
      url: image,
    },
    mainEntityOfPage: canonical,
  };

  const getSchema = (type) => {
    switch (type) {
      case "Article":
        return schemaData_Article;
      default:
        return schemaData_WebPage;
    }
  };

  const metaDescription =
    description.replace(/<([^>]+)>/gi, "").replace(/[\r\n]+/g, " ") ||
    site.siteMetadata.description; // Strip html and line breaks
  const metaImage = image || site.siteMetadata.baseUrl + placeholderImage;

  let windowUrl = typeof window !== "undefined" ? window.location.href : "";
  let canonicalUrl =
    canonical && canonical.indexOf("http") === 0 ? canonical : windowUrl;

  if (canonicalUrl) {
    try {
      let canon = new URL(canonicalUrl);
      canon.hostname = "www.travelnursing.org";
      canon.port = "";
      canon.pathname = canon.pathname.replace(/[.]php[/]?$/, "");
      if (canon.pathname[canon.pathname.length - 1] !== "/") {
        canon.pathname += "/";
      }
      canonicalUrl = canon.toString();
    } catch (e) {
      console.log("canon failure: ", e);
    }
  }

  // Add trailing slashes to the canonical URL
  // Bypass the parsing at your own risk
  if (hardcodedCanonicalUrl) {
    canonicalUrl = hardcodedCanonicalUrl;
  }

  if (!canonicalUrl) {
    console.log(`no possible canonical url for "${title}"`);
  }

  const icon = { rel: "shortcut icon", type: "image/png", href: `${favicon}` };
  const link = canonicalUrl
    ? [{ rel: `canonical`, href: canonicalUrl }, icon]
    : [icon];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={link}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: canonicalUrl,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.twitter,
        },
        {
          itemprop: `image`,
          content: metaImage,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(getSchema(schemaType))}
      </script>
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `en`,
  url: ``,
  meta: [],
  keywords: [],
  description: ``,
  canonical: ``,
  schemaType: "WebPage",
  hardcodedCanonicalUrl: "",
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  image: PropTypes.string,
  canonical: PropTypes.string.isRequired,
  schemaType: PropTypes.string,
  hardcodedCanonicalUrl: PropTypes.string,
};

export default Seo;

import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PostGrid from "../ui/PostGrid";
import styled from "styled-components";
import defaultSmall from "../images/default-sm.jpg";

const ExternalImage = styled.span`
  display: block;
  height: 170px;
  background-size: cover;
  background-position: center;
  background-color: #fafafa;
`;

// Create read estimate by counting the words
const readEstimate = (content) => {
  const minutes = Math.round(
    content.replace(/(<([^>]+)>)/gi, "").split(" ").length / 240,
  );
  return minutes ? minutes : 1;
};

const PostGridItem = ({
  slug,
  title,
  fluidImage,
  imageUrl,
  width,
  date,
  content,
}) => {
  return (
    <PostGrid.Item width={width}>
      <Link to={`/${slug}/`}>
        {fluidImage ? (
          <Img fluid={fluidImage} alt={title} />
        ) : (
          <ExternalImage
            style={{
              backgroundImage: `url(${imageUrl ? imageUrl : defaultSmall})`,
            }}
          />
        )}
        <h3
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {date && (
          <span>
            {date} {content && <>- {readEstimate(content)} min read</>}
          </span>
        )}
      </Link>
    </PostGrid.Item>
  );
};

export default PostGridItem;

import { StaticQuery, graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import PostGridItem from "../components/PostGridItem";
import get from "lodash/get";
import theme from "../ui/theme";

const RelatedArticleWrapper = styled.section`
  background: #fff;
  padding: 50px 20px;
  h2 {
    font-size: 20px;
    margin: 0 0 3rem;
    padding: 0;
    text-align: center;
  }
  .article__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;

    @media all and (max-width: 1024px) {
      max-width: 625px;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      max-width: 400px;
    }
  }
`;

const RelatedArticles = ({ heading, relatedArticles }) => (
  <StaticQuery
    query={graphql`
      query fallbackImage {
        placeholderImage: file(relativePath: { eq: "default.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <RelatedArticleWrapper className="related-articles">
        <h2>{heading}</h2>
        <div className="article__inner">
          {relatedArticles.map((item) => {
            return (
              <PostGridItem
                key={item.node.slug}
                slug={item.node.slug}
                title={item.node.title}
                fluidImage={get(
                  item,
                  "node.featured_media.localFile.childImageSharp.fluid",
                  data.placeholderImage.childImageSharp.fluid,
                )}
                width="23%"
                date={item.node.date}
                content={item.node.content}
              />
            );
          })}
        </div>
      </RelatedArticleWrapper>
    )}
  />
);

RelatedArticles.defaultProps = {
  heading: `You may also like`,
  relatedArticles: [],
};

export default RelatedArticles;
